/*
  @author Félix Fuin / Friedrich Tane Tsamo
  Copyright Nokia 2017. All rights reserved.
*/

import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import renderHTML from 'react-render-html';
import BottomScrollListener from "react-bottom-scroll-listener";
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import {BackTop, Alert, Col, Divider, Row, Tag, Button, Modal, notification, Icon/*, Table*/} from "antd";

import Source from '../tools/data';
import HeaderComponent from './Header';
import Thumbnail from './Thumbnail';
import B from '../tools/back';
import NotFound from './NotFound';
import FooterComponent from './Footer';
import EditCtl from '../tools/editCtl';
import wipC from '../tools/editWip';
// import h2p from 'html2plaintext';
// import {saveAs} from 'file-saver';


import 'antd/dist/antd.css';
import '../css/landing.css';
import '../css/landing3.css';
import '../css/collection.css';

import Remote from '../util/remote';
import ViewsAndLikes from '../components/viewsAndLikes';
const csod = Remote('create', 'printCatalog', 'https://learningstore.nokia.com/srv1');

  // fetch(`https://nokia.sharepoint.com/_api/SP.UserProfiles.PeopleManager/GetPropertiesFor(accountName=@v)?@v='i:0%23.f|membership|${'friedrich.tane_tsamo@nokia.com'}'`,{headers:{'content-type':'application/json', Accept:'application/json'}})
  //     .then(rep => rep.json())
  //     .then (rep => console.log(rep))
  //     .catch(err => console.log(err))

const SortableItem = SortableElement( ({value}) => <div className="sortElt">{value}</div> );

const SortableList = SortableContainer( ({items}) => 
  <div style={{display:'flex', flexWrap: 'wrap' }}>
    {items.map( (value, index) => <SortableItem key={`item-${index}`} index={index} value={value} /> )}
  </div>
);

const wipNames = ['wip', 'unsaved', 'admin', 'ready', 'review'];

class SortableComponent extends Component {

  render() {
    let oldCollSolutions = this.props.currentColl.Solutions;
    let oldID = this.props.currentColl.ID;
    this.props.currentColl.Solutions = this.props.items.map( item => item.key );
    if ( oldCollSolutions.some( (item, i) => item !== this.props.currentColl.Solutions[i] && oldID === this.props.id && oldCollSolutions.length === this.props.currentColl.Solutions.length ) ) {
      const id = this.props.id;
      EditCtl._push(B.origin[id], this.props.currentColl);
      wipC.save(id);
    }
    return <SortableList items={this.props.items} onSortEnd={this.props.onSortEnd}  distance={2} axis="xy" />;
  }
};

const getOldStorage = (id) => {
  const edit = JSON.parse(localStorage.edit);
  let rst;
  edit.forEach(item => { if (item.old.ID === id) rst = item.old; });
  if (rst) {
    rst.del = true;
    rst.Wip = true;
    rst.Format = 'Archived'
  }
  return rst;
}

// Display the scope of Priority Group (Priority Store only)
function Scope(props) {
  var scope = props.data
  var res = scope.map((itm, idx) => {
      var val
      var valid = false
      var s=[]
      for (val in itm) {
        if (s.length !== 0) s.push(<span>and</span>)
        valid = valid || val === "L1" || val === "L2" || val === "L3" || val ==="L4" || val ==="L5"
        s.push(<Tag color="blue" style={{marginRight: '7px', marginLeft: '7px'}}>'{val}' is '{itm[val]}'</Tag>)
      }
      if (!valid) {
        s.push(<Alert message="Error: Scope is not valid. Each scope must contain an organization (L1..L5)" type="error" showIcon />)
      }
      s.push(<br />)
      if (idx !== scope.length-1) s.push(<div style={{margin: '7px'}}>OR</div>)
      return s
  })
  return <div>{res}</div>
   // <Tag className={'collectionLink'}>{item.title}</Tag>
  //return <div>Hello{JSON.stringify(props)}</div>
}



export default class Collection extends Component {
  
  state = { isLoading: true, lim: 20, thumbnails: [], loadPDF: false, currentUser: null }
  storeDef;
  
  print(ID, appendix){
    this.setState({loadPDF: true});
    csod.printCatalog(ID, 'catalog', appendix)
    .then( rep => {
      //console.log(rep)
      if(rep.err){
        notification.error({
          message: 'Error',
          description: "The request failed",
          placement: 'bottomRight'
        });
        this.setState({loadPDF: false});
      }else{
        const byteCharacters = atob(rep.rep);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        // saveAs(new Blob([new Uint8Array(byteNumbers)], {type: 'application/pdf'}), title.replace(/ /g, '_') + '.pdf');
        window.open('https://learningstore.nokia.com/customer/catalog/' + ID + '.pdf', 'tab');
        this.setState({loadPDF: false});
      }
    })
    .catch(err => console.log(err))
  }
  
  showModal = (ID, title) => {
    const self = this;
    Modal.confirm({
      title: 'Do you want to include the course descriptions to the catalog ?',
      content: 'The course descriptions contain more detailed information on each course but results in a larger file.',
      cancelText: 'No',
      okText: 'Yes',
      onOk() {
        self.print(ID, true);
      },
      onCancel() {
        self.print(ID, false);
      }
    });
    
    
  }
  
  loadMore = () => this.setState({ lim: this.state.lim + 40 });
  
  forceUpdate() { // to reset thumbnails when switching edit mode
    const {id} = this.props.match.params;
    const coll = this.store.getByID(id);
    if(!coll.Flat) this.setState({thumbnails : this.map(coll, coll.Solutions, coll.Flat).props.children});   
  }
  
  UNSAFE_componentWillMount() {
    document.body.style.overflow = 'auto';
    window.scrollTo(0, 0);
    const {name,id} = this.props.match.params;
    Source.getSync(name)
    .then(store => {
      this.store = store;
      const coll = store.getByID(id);
      if (coll) {
        B.origin[coll.ID] = JSON.stringify(coll);
        this.storeDef = Source.getDef(name);
        B.ga(this.storeDef.analytics);
        B.pageview('/' + name + '/index.html?filter=' + encodeURIComponent(coll.Title));
        B.incView(name, id);
        B.back = true;
        this.setState({isLoading: false, thumbnails: !coll.Flat && !coll.Html ? this.map(coll, coll.Solutions, coll.Flat).props.children : null });
      }
      else this.setState({isLoading: false});
    });
  }
    
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {id, name} = this.props.match.params;
    let nextid = nextProps.match.params.id;
    
    if(nextid !== id){
      const coll = this.store.getByID(nextid);
      if (coll) {
        B.origin[coll.ID] = JSON.stringify(coll);
        B.pageview('/' + name + '/index.html?filter=' + encodeURIComponent(coll.Title));
        B.incView(name, nextid);
        if(!coll.Flat) this.setState({thumbnails : this.map(coll, coll.Solutions, coll.Flat).props.children});
      }
      else B.history.goBack();
    }
  }
    
  undo() { // to set thumbnails on undo / redo operations
    const {id} = this.props.match.params;
    const coll = this.store.getByID(id);
    //console.log('undo coll', coll)
    B.origin[coll.ID] = JSON.stringify(coll);
    if(!coll.Flat) this.setState({thumbnails : this.map(coll, coll.Solutions, coll.Flat).props.children});
  }

  handleSend = () => {
    let item = this.store.getByID(this.props.match.params.id);
    B.addToCart(item.ID,item.Title);      
    this.setState({disabledSend: true});        
  }
    
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState({ thumbnails: arrayMove(this.state.thumbnails, oldIndex, newIndex) });
    EditCtl._reload();
  }

  removeMethod = (id) => {
    Modal.confirm({
      title: 'Do you really want to cancel changes for this item?',
      content: "This action cannot be undone. The item will come back to its previous state. (To definitively remove an item from the Learning Store, open it and click the 'Archive' button.)",
      onOk() {
        let old, i=0;
        const storage = JSON.parse(localStorage.edit);
        storage.forEach(item => { if (item.old.ID === id && i===0) {old = item.old; i=1;}; });
        EditCtl.update(old);
        EditCtl._pop(id);
        wipC.removeID(id);
        EditCtl._reload();
      }
    });
  }
  
  map(coll, solutions, flat) {

    // console.log(solutions)

    if (!solutions) return null; // for Leadership-like collections

    const {name} = this.props.match.params;
    let ret = solutions.map( itemID => this.store.getByID(itemID) || getOldStorage(itemID));
    // console.log('ret', ret)
    ret = ret.filter ( item => item && (!item.sid || item.sid === name)  && /*((item.Icon /*&& !item.del) || coll.ID === 'wip' ) &&*/ (EditCtl.editMode || !item.Wip) );
    //console.log('map', coll, ret);
    
    if (!flat) {
      ret = ret.map( item => 
        <div key={item.ID} style={{marginTop: 10, marginBottom: 20}} className={'class ' + (coll.ID === 'wip' && wipNames.indexOf(item.ID) === -1 && 'thumb')}>
          {coll.ID === 'wip' && wipNames.indexOf(item.ID) === -1 && 
            <Button className="closeButton" onClick={() => {this.removeMethod(item.ID)}}>Cancel Changes</Button>}
          <Thumbnail props={this.props} currentUser={this.state.currentUser} data={item} store={this.storeDef}/>
        </div>
      );
      
      if(!EditCtl.editMode){
        // console.log(ret.length);
        if (ret.length > this.state.lim + 1) {
          ret = ret.slice(0, this.state.lim);
          return (
            <Row gutter={16} type="flex" justify={'center'} align="top">
              {ret}
            </Row>
          );
        }
      }

      return <Row type="flex" align="top" gutter={16}>{ret}</Row>;
    }
    
    else {
      const rst = [];
      let tmp = ret.filter( item => !item.Solutions );
      tmp = tmp.map( (item, index) => (
        <div key={item.ID} style={{marginTop: 20, marginBottom: index === tmp.length-1 ? 40 : 20}}>
          <Thumbnail props={this.props} currentUser={this.state.currentUser}  data={item} store={this.storeDef}/>
        </div>
      ));      
      rst.push(<Row type="flex" align="top" gutter={16} key={0}>{tmp}</Row>);
      
      ret = ret.filter( item => item.Solutions ).map( coll => {
        // const src = Source.decodeIcon(coll, this.storeDef.url);
        return (
          <div key={coll.ID} className="childCollection">
            <Row type="flex" className="flatTitle">
              <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                
                  <h2 className='nokiaFontTitle'>
                  {coll.Title}
                 </h2>
   
                <div>
                  {/* <img src={src} /> */}
                  {renderHTML(coll.Description || '')} 
                </div>
              </Col>
            </Row>     
              <div style={{marginBottom:40}}>    
                {this.map(coll, coll.Solutions, false)}
              </div>
          </div>
        );
      });
      rst.push(<Row type="flex" align="top" gutter={16} key={1}>{ret}</Row>);
      return rst;
    }
  }


  render() {

    //console.log("Collection: current user is ", this.state.currentUser)

    if (this.state.isLoading) return null;
       
    B.set(this);
    
    const {id, name} = this.props.match.params;
    const coll = this.store.getByID(id);
    
    if (!coll) return <NotFound {...this.props}/>

    if (!coll._admin) {
      B.currentColl = coll;
    }
    else {
      if (!B.intranet) return <Redirect to={"/" + name}/>
      B.currentColl = null;
      EditCtl.switchEditMode(true, false);
    }

    // if (id !== "wip" && id !== "unsaved" && id !== "admin") B.currentColl = coll;
    // else B.currentColl = null;

    // if (id === "wip" || id === "unsaved" || id === 'admin') {
    //   if (!B.intranet) return <Redirect to={"/" + name}/>
    //   EditCtl.switchEditMode(true, false);
    // }

    // this.tableData = coll.Solutions.map((itemID, key) => {
    //   let item = this.store.getByID(itemID);
    //   let desc;
    //   if(item && item.Description) desc = renderHTML(item.Description);
    //   return {
    //     key: key+1,
    //     title: [item.Title, item.ID, name],
    //     duration: (item && item.Duration && renderHTML(item.Duration)) || '<No duration>',
    //     description: desc,
    //     format: item.Format || (item.Solutions ? 'Collection' : 'Learning Object'),
    //   };
    // })
        
    const thumbnailsElts = this.map(coll, coll.Solutions, coll.Flat);
    
    //BV INCLUDEIN PROBLEM  
    // Retrieve the list of parents of current item;
    // Used to display the "Included In" of a collection
    const parentCollections = Source.getParentCollections(name, coll.ID);

    const mapping = this.storeDef.collectionMapping;
    const fields = mapping && mapping.filter( field => !field.startsWith('_') ).map( (field, index) => {
      let disp = field.split('|disp:')[1];
      if (disp) disp = disp.split(/(=|\|)/)[0];
      field = field.split('|')[0];
      const short = field.replace('*', '');
      if (coll[short] && short !== 'Tagline'){

        // ONLY FOR PRIORITY EDITOR
        if (short === 'Scope')
          return (
            <Row type="flex" justify="center" key={index}>
              <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                <div className="itemFieldTitle">Scope</div>
                <div className="itemFieldBody">
                  <Scope data={coll[short]}></Scope>
                </div>
              </Col>
            </Row>)

        if(short === 'Owner')
          return (this.state.currentUser ?
            <Row type="flex" justify="center" key={index}>
              <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                <div className="itemFieldTitle">
                Need Help?
                </div>
                <div className="itemFieldBody">
                  {/* {coll[short]} */}
                  {renderHTML(`<div>${coll[short].replace(') <', ') &lt;').replace('.com>', '.com&gt;')}</div>`)}
                </div>
              </Col>
            </Row> : null); 
        
        if(typeof coll[short] === 'boolean'){
          return (
            <Row type="flex" justify="center" key={index} className="row">
              <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                <div className="itemFieldTitle col">
                  {disp || short}
                </div>
              </Col>
            </Row>); 
        }
        return (
          <Row type="flex" justify="center" key={index}>
            <Col xs={22} sm={20} md={20} lg={18} xl={18}>
              <div className="itemFieldTitle">
                {disp || short}
              </div>
              <div className="itemFieldBody">
                {renderHTML(`<div>${coll[short]}</div>`)}
              </div>
            </Col>
          </Row>); 
      } 
      return null;
    });

    const catalogImage = `${process.env.PUBLIC_URL}/img/catalog-page-bnr.png`;
    const imgTitle = (coll.Format==='Catalog') ? (
      <Row type="flex" justify="center" className="collTitle">
          <Col xs={22} sm={20} md={20} lg={18} xl={18} className="containTitle">
            <img src={catalogImage} alt="" style={{ width: 100 + '%' }} />
            <div className="centered white">
              <h1>{coll.Title}</h1>
              <div><div className="tagline">{renderHTML(coll.Tagline || '')}</div></div>          
            </div>
          </Col>
          <Col xs={22} sm={20} md={20} lg={18} xl={18} className="desc2">
            {renderHTML(coll.Description || '')}
          </Col>
        </Row>
    ) : (
      <Row type="flex" justify="center" className="collTitle">
          <Col xs={22} sm={20} md={20} lg={18} xl={18}>
              <h2 style={EditCtl.editMode && coll.Wip ? {color: 'orange'} : {} }>{coll.Title}</h2>
              <div className = "tagline"><i>{renderHTML(coll.Tagline || '')}</i></div>
          </Col>         
          <Col xs={22} sm={20} md={20} lg={18} xl={18}>
            {renderHTML(coll.Description || '')}
          </Col>
        </Row>
    );
    
    const disp = (coll.Html) ? ( // support for landing pages
      <Row type="flex" justify="center">
        <Col xs={22} sm={20} md={20} lg={18} xl={18}>
          <div>{renderHTML(coll.Html || '')} </div>
        </Col>
      </Row>
    ) : (
      <div>

        {imgTitle}
        <div className='thumbnails'>
          <Row type="flex" justify="center">
            <Col xs={22} sm={20} md={20} lg={18} xl={18}>
              {EditCtl.editMode && /*!coll.Exclude &&*/ !coll.Flat &&  wipC.isNotWip(coll) ?
                <SortableComponent   items={this.state.thumbnails} currentColl={coll} onSortEnd={this.onSortEnd} id = {id} /> : thumbnailsElts }
            </Col>
          </Row>
        </div>
        
        { parentCollections ?        
          <Row type="flex" justify="center" style={{marginTop:'10px'}}>
            <Col xs={22} sm={20} md={20} lg={18} xl={18}>
              <div className="itemFieldTitle">Included In</div>
              <div className="itemFieldBody">
                {!coll.Flat && ((parentCollections.map( (item, index) => {
                    const itm2 =  this.store.getByID(item.id);

                    if (EditCtl.editMode && itm2.Status!=="Published")
                      return  <Link to={"/" + coll.sid + "/item/" + item.id} key={index}>
                                <Tag className={'collectionLink'} style={{color:'orange'}}>{item.title}</Tag>
                              </Link>

                    return  <Link to={"/" + coll.sid + "/item/" + item.id} key={index}>
                              <Tag className={'collectionLink'}>{item.title}</Tag>
                            </Link>
                    })) 
                )} 
              </div>
            </Col>
          </Row>
        :null
      }


        {fields}
        {this.storeDef.Recommendations && coll.recommended && coll.recommended.length &&
        <div>
          <Row type="flex" justify="center">
            <Col xs={22} sm={20} md={20} lg={18} xl={18}>
              <Divider orientation="left"><h2 className={'nokiaFontTitle'}>Recommended Learning</h2>
              </Divider>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={22} sm={20} md={20} lg={18} xl={18}>
            <div style={{paddingBottom:40}}>{this.map(coll, coll.recommended)}</div>
            </Col>
          </Row>
        </div>
        }
      </div>
    );

    const cartdisabled = B.shopcart.some( (cart) => cart.id === coll.ID)

    return (
      <div>
        {this.state.currentUser && <ViewsAndLikes item={coll} style={{position: 'fixed', top:'64px', left:'3px', zIndex:'1000'}} />}
        <div style={{margin:0}}>
          <div id="loader" className="printLoader" style={{display: this.state.loadPDF ? 'block' : 'none'}}></div>
        </div>
        <div className={this.state.loadPDF ? "loadPrint" : ""} >
          <div id="editDimmer">
            <div><img src="" id="editDimmerImg" alt="Dimmer"/>
              <div id="editDimmerText"></div>
            </div>
          </div>
          <div className="head">
            <HeaderComponent {...this.props} title={coll.Title} setCurrentUser={p => this.setState({currentUser: p})} data={this.storeDef}/>
          </div>
          <div className="store">
            {(coll.Format === 'Catalog') && (<Button type='ghost' onClick={() => this.showModal(id, coll.Title)} className='collapseB printButton' icon={'printer'}> Print Catalog</Button>)}
            {coll.Format === 'Catalog' && this.storeDef.hasCart && !EditCtl.editMode && <Button style={{margin:'0 10px'}} onClick={this.handleSend} 
              disabled={cartdisabled} className={"collapseB cartButton" + (cartdisabled ? " disabled" : "")} type="ghost"><Icon type="info-circle" />{cartdisabled ? "Added":"Add to Info Cart"}</Button>}
            {disp}
            <Row type="flex" justify="center">
              <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                <div className="lastModified">
                  {coll.name && EditCtl.editMode && <div><br></br>Last submitted
                    by {coll.name} on {new Date(coll.date).toISOString().replace('T', ' ').slice(0, 19)}</div>}
                </div>
              </Col>
            </Row>
          </div>
          <FooterComponent props={this.props} data={this.storeDef}/>
          <BackTop visibilityHeight={1500}/>
          <BottomScrollListener onBottom={this.loadMore} offset={1000}/>
        </div>
      </div>

    );

    // document.getElementById("edit").style.display = 'block';
  }
}