import React, {Component} from 'react';
import {BackTop, Row, Col, Tag, Button, Table, Collapse, Icon, Modal, notification} from "antd";
import renderHTML from 'react-render-html';
import {Link} from 'react-router-dom';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import Source from '../tools/data';
import HeaderComponent from './Header';
import Thumbnail from './Thumbnail';
import B from '../tools/back';
import NotFound from './NotFound';
import FooterComponent from './Footer';
import EditCtl from '../tools/editCtl';
import wipC from '../tools/editWip';
// import h2p from 'html2plaintext';
import ViewsAndLikes from '../components/viewsAndLikes';
import ZoomImage from '../components/zoomImage';

import 'antd/dist/antd.css';
import '../css/landing.css';
import '../css/landing3.css';
import '../css/Topic.css';

import Remote from '../util/remote';

const csod = Remote('create', 'printCatalog', 'https://learningstore.nokia.com/srv1');

const Panel = Collapse.Panel;

const Formats = [
    'Book',
    'Certification',
    'Collection - TP',
    'Curriculum',
    'Event',
    'Event - Classroom',
    'Event - Virtual Class',
    'External Resources',
    'External Training',
    'Library',
    'Material',
    'Material - SWAY',
    'Online Class',
    'Online Class - Self Assessment',
    'Program',
    'Test',
    'Video'
  ];
  
  const formatMaps  = Formats.map( icon => icon.toLowerCase());

  const customPanelStyle = {
    background: '#f7f7f7',
    borderRadius: 4,
    // marginBottom: 24,
    // border: 0,
    overflow: 'hidden',
  };

const SortableItem = SortableElement( ({value}) => <div className="sortElt">{value}</div> );

const SortableList = SortableContainer( ({items}) => 
  <div style={{display:'flex', flexWrap: 'wrap' }} >
    {items.props.children.map( (value, index) => <SortableItem key={`item-${index}`} index={index} value={value} /> )}
  </div>
);

class SortableComponent extends Component {

  render() {
    // console.log('storing in WIP', oldCollSolutions, this.props.currentColl.Solutions);
    return <SortableList items={this.props.items} onSortEnd={this.props.onSortEnd} distance={2} axis="xy" />;
  }
};

export default class Topic extends Component{

    state = {isLoading: true, toggle: true, expanded: [], loadPDF: false, thumbnails: [], currentUser: null };
    storeDef; 
    idCurrentSection;    

    UNSAFE_componentWillMount() {
        document.body.style.overflow = 'auto';
        window.scrollTo(0, 0);
        const {name,id} = this.props.match.params;
        Source.getSync(name)
        .then(store => {
          this.store = store;
          const topic = store.getByID(id);
          if (topic) {
            B.origin[topic.ID] = JSON.stringify(topic);
            this.storeDef = Source.getDef(name);
            B.ga(this.storeDef.analytics);
            B.pageview('/' + name + '/index.html?filter=' + encodeURIComponent(topic.Title));
            B.back = true;
            B.incView(name, topic.ID);
            let thumbs = [];
            topic.Sections.forEach(section => {
                if(section.type === "objects") thumbs.push(section);
            })
            this.setState({isLoading: false, thumbnails: thumbs});
          }
          else this.setState({isLoading: false});
        }); 
        this.setState({expanded: B.expandedTopic});
    }

    print(ID, appendix){
        this.setState({loadPDF: true});
        csod.printCatalog(ID, 'trainingSolution', appendix)
        .then( rep => {
          if(rep.err){
            notification.error({
              message: 'Error',
              description: "The request failed",
              placement: 'bottomRight'
            });
            this.setState({loadPDF: false});
          }else{
            const byteCharacters = atob(rep.rep);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            // saveAs(new Blob([new Uint8Array(byteNumbers)], {type: 'application/pdf'}), title.replace(/ /g, '_') + '.pdf');
            window.open('https://learningstore.nokia.com/customer/training_solution/' + ID + '.pdf', 'tab');
            this.setState({loadPDF: false});
          }
        })
        .catch(err => console.log(err))
      }

      handleSend = () => {
        let item = this.store.getByID(this.props.match.params.id);
        B.addToCart(item.ID,item.Title);      
        this.setState({disabledSend: true});        
      }

      onSortEnd = ({oldIndex, newIndex}) => {
        if(oldIndex !== newIndex){
            const {id} = this.props.match.params;
            let topic = this.store.getByID(id);
            topic.Sections.forEach((section, index) => {
                if (section.ID === this.idCurrentSection){
                    section["objects_"+section.ID] = arrayMove(section["objects_"+section.ID], oldIndex, newIndex);
                    this.setState({toggle: !this.state.toggle});
                }
            })
            EditCtl._push(B.origin[id], this.store.getByID(id));
            wipC.save(id);
            B.origin[id] = JSON.stringify(this.store.getByID(id));
            EditCtl._reload();
        }
      }

      showModal = (ID, title) => {
        const self = this;
        Modal.confirm({
          title: 'Do you want to include the course descriptions to the catalog ?',
          content: 'The course descriptions contain more detailed information on each course but results in a larger file.',
          cancelText: 'No',
          okText: 'Yes',
          onOk() {
            self.print(ID, true);
          },
          onCancel() {
            self.print(ID, false);
          }
        });
        
    
      }

    map(ret, seq) {
        if (!ret) return null; // for Leadership-like collections
        const {name} = this.props.match.params;
        this.storeDef = Source.getDef(name);
        ret = ret.filter( id => id && this.store.getByID(id) && (!this.store.getByID(id).sid || this.store.getByID(id).sid === name)  && /*((item.Icon /*&& !item.del) || coll.ID === 'wip' ) &&*/ (EditCtl.editMode || !this.store.getByID(id).Wip) );
        ret = ret.map( (id, index) => 
            <div key={id} style={{marginTop: 10, marginBottom: 10}} className='thumb'>
                <Thumbnail props={this.props} currentUser={this.state.currentUser} data={this.store.getByID(id)} store={this.storeDef}/>
                {seq && <div className="seqItem">{index + 1}</div>}
            </div>
        );
        return <Row type="flex" align="top" gutter={16} className="objects">{ret}</Row>;
    }
        
    TopicSection = (data) => <div className={ data.type === "title" ? "titleSection" : "topicSection"} onMouseOver={() => {this.idCurrentSection = data.ID}}>
                        {/* <h2>{data["title_"+data.ID]}</h2> */}
                        {data.type !== "title" && <div className='text'>
                            {data["text_"+data.ID] && renderHTML(data["text_"+data.ID])}
                        </div>}
                        {data.type !== "title" && data.type !== "text" && <div className="contain">
                            {EditCtl.editMode && (data.type === "objects" || data.type === "collection") && <Button type='ghost' onClick={() => this.switchThumbTable(data.ID)} className='collapseB tableButtonT'>{this.tableKey.indexOf(data.ID) === -1 ? 'Sortable Thumbnails View (click to change)' : 'Table View (click to change)'}</Button> }
                            {data.type === "image" && <ZoomImage src={data["url_"+data.ID]} alt='' className="main"/>}
                            {data.type === "title" && <h1 className="main">{data["title_"+data.ID]}</h1>}
                            {data.type === "objects" && (
                                (this.tableKey.indexOf(data.ID) >= 0) 
                                    ? <Table 
                                        columns={this.columns} 
                                        dataSource={this.makeTable(data["objects_"+data.ID])} 
                                        expandedRowRender={record => <div style={{ margin: 0 }}>{record.description || '<No description for this object>'}</div>} 
                                        className='tableSection' 
                                        bordered pagination={false} /> 
                                    : EditCtl.editMode 
                                        ? <SortableComponent 
                                            items={this.map(data["objects_"+data.ID], data["sequence_"+data.ID])} 
                                            onSortEnd={this.onSortEnd} /> 
                                        : this.map(data["objects_"+data.ID], data["sequence_"+data.ID])
                            )}
                            {data.type === "video" && (data["url_"+data.ID].indexOf('.mp4') === -1 ? <iframe width="700" src={data["url_"+data.ID].replace("&amp;", "&")} className="main" title="video"/> : <video controls width="700"><source src={data["url_"+data.ID]} type="video/mp4"/></video>)}
                            {data.type === "collection" && ((this.tableKey.indexOf(data.ID) >= 0) ? 
                            <Table 
                                columns={this.columns} 
                                dataSource={this.makeTable(this.store.getByID(data["objects_"+data.ID][0]).Solutions)} 
                                expandedRowRender={record => <div style={{ margin: 0 }}>{record.description || '<No description for this object>'}</div>} 
                                className='tableSection' bordered pagination={false} 
                            /> : (this.map(this.store.getByID(data["objects_"+data.ID][0]).Solutions, false)))}
                        </div>}
                    </div>

    imageFile = (format) => {
        const url = `${process.env.PUBLIC_URL}/img/Icon-${(format || 'filler').trim().replace(/ /g, '')}.png`

       // Test if image exists. If not return default image
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status === 404) return `${process.env.PUBLIC_URL}/img/Icon-Material.png`

        return url
  }


    columns = [{
        title: '',
        dataIndex: 'icon',
        key: 'icon',
        render : (format) => {
            if (format) {
            let form = format.toLowerCase().trim();
            if((format === 'Catalog')||(format === 'Collection')) form = 'collection - tp';
            const iconIndex =  formatMaps.findIndex( icon => icon === form );
            return iconIndex > -1 ?
                <img src={this.imageFile(format)} alt='' className='iconThumb tableIcon' height={30}/>
                : null;
            }
        },
        align: 'center',
    }, {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: ([text, id, name]) => <Link className="link" to={{pathname: `/${name}/item/${id}`}}>{text}</Link>,
    }, {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        width: '20%',
    }, {
        title: 'Format',
        key: 'format',
        dataIndex: 'format',
        width: '20%',
    }];

    tableData = [];

    tableKey = [];
    

    switchThumbTable = (id) => {
        this.tableKey =  this.tableKey.indexOf(id) === -1 ? 
            [...this.tableKey, id] : [...this.tableKey].filter(elt => elt !== id);

            let item = this.store.getByID(this.props.match.params.id);
            B.origin[item.ID] = JSON.stringify(item);
            item.Sections.forEach(section => {
                if(section.ID === id) section.table = !section.table;
            });
            EditCtl.update(item);
            EditCtl._push(B.origin[item.ID], item);
            wipC.save(item.ID);
            this.setState({toggle: !this.state.toggle});
    }

    makeTable(solutions){
        this.tableData = [];
        solutions.forEach((itemID, key) => {
            let item = this.store.getByID(itemID);
            let desc = "";
            if(item.Description) desc = renderHTML(item.Description);
            let form = item.Format || (item.Solutions ? 'Collection' : 'Learning Object');
            if(!item.Wip || EditCtl.editMode) this.tableData.push({
                key: key+1,
                icon: form,
                title: [item.Title, item.ID, this.props.match.params.name],
                //duration: (item.Duration && renderHTML(item.Duration)) || 'No duration for '+form+'s',
                duration: (item.Duration && renderHTML(this.reformatedDuration(item.Duration, form))) || 'No duration for '+form+'s',
                description: desc,
                format: form,
            });
        });
        return this.tableData;
    }

    reformatedDuration(duration,format) {
        if (format!=='Event') return duration
        
        let correct = duration.match(/[0-9]+:[0-9][0-9] hour\(s\)/) 
        if (correct) {
            let breakdown = correct[0].match(/[0-9]+/g)
            let H = parseInt(breakdown[0])
            let M = parseInt(breakdown[1])
            if (H%7===0 && M===0)
                return Math.floor(H/7).toString()+ " day(s)"
            if (H%7===3 && M===30)
                return Math.floor(H/7).toString() +",5 day(s)"
            else return duration
        }
        return duration
    }

    render(){

        if(this.props.location.state && this.props.location.state.scrollTopic){
            window.scrollTo(0, 0); 
            this.props.location.state.scrollTopic = false;
        } 

        if (this.state.isLoading) return null;

        // if (this.props.match.params.edit === "edit") EditCtl.switchEditMode(true, true);
    
        B.set(this);
        
        const {id, name} = this.props.match.params;
        const topic = this.store.getByID(id);

        if (!topic) return <NotFound {...this.props}/>

        this.tableKey = [];
        topic.Sections.forEach(section => {
            if(section.table && this.tableKey.indexOf(section.ID) === -1) this.tableKey.push(section.ID);
        });
      

        const src = topic.tImage ? `${process.env.PUBLIC_URL}/${topic.tImage}` : `${process.env.PUBLIC_URL}/img/title.jpg`;
        //const imgTitle =<Row type="flex" justify="center" className="topicTitle">
        //                    <Col xs={22} sm={22} md={22} lg={22} xl={22}>
        //                        <img src={src} alt="" style={{ width: '100%' }} />
        //                        <div className={EditCtl.editMode && topic.Wip ? "centered" : "centered white"}>
        //                            <h1 style={EditCtl.editMode && topic.Wip ? {color: 'orange'} : {} }>{topic.Title}</h1>
        //                            <div className="tagline">{renderHTML(topic.Tagline || '')}</div>
        //                        </div>
        //                    </Col>
        //                    <Col xs={22} sm={22} md={22} lg={18} xl={17} className="desc2 topicDesc">
        //                        {renderHTML(topic.Description || '')}
        //                    </Col>
        //                </Row>;


        // Images of Topic pages
        const imgTitle =<div justify="center" className="topicTitle">
                            <div className="titleBox">
                                <img src={src} alt="" style={{ width: '100%', minHeight: '230px' }} />
                                <div className="titleText">
                                    <div className="topicPageTitle" style={EditCtl.editMode && topic.Wip ? {color: 'orange'} : {} }>{topic.Title}</div>
                                    {topic.Tagline? <div className="tagLineBackground"><div className="tagline ">{renderHTML(topic.Tagline || '')}</div></div>:null}
                                </div>
                            </div>
                            <div xs={22} sm={22} md={22} lg={18} xl={17} className="desc2 topicDesc">
                                {renderHTML(topic.Description || '')}
                            </div>
                        </div>;


        //const dataCollections = Source.getCollections(name, topic.ID);
        const parentCollections = Source.getParentCollections(name, topic.ID);

        const cartdisabled = B.shopcart.some( (cart) => cart.id === topic.ID)

        return(
            <div>
                {this.state.currentUser && <ViewsAndLikes item={topic} style={{position: 'fixed', top:'64px', left:'3px', zIndex:'1000'}}/>}
                <div id="editDimmer">
                    <div><img src="" id="editDimmerImg" alt="Dimmer"/>
                        <div id="editDimmerText"></div>
                    </div>
                </div>
                <div style={{margin:0}}>
                    <div id="loader" className="printLoader" style={{display: this.state.loadPDF ? 'block' : 'none'}}></div>
                </div>
                <div className={this.state.loadPDF ? "loadPrint" : ""} >
                    <div id="editDimmer">
                        <div><img src="" id="editDimmerImg" alt="Dimmer"/>
                        <div id="editDimmerText"></div>
                        </div>
                    </div>
                    <div className="head">
                    <HeaderComponent {...this.props} title={topic.Title} setCurrentUser={p => this.setState({currentUser: p})} data={this.storeDef}/>
                    </div>
                    <div className="store storeTopic">
                        <div className="buttonBoxTopic">
                            
                            {(topic.Format === 'Training Solution') && (<Button 
                                                                                type='ghost' 
                                                                                onClick={() => this.showModal(id, topic.Title)} 
                                                                                className='collapseB printButtonTopic' 
                                                                                icon={'printer'}> 
                                                                            Print Solution
                                                                        </Button>)}

                            {this.storeDef.hasCart && !EditCtl.editMode && <Button 
                                                                                    style={{margin:'0 10px'}} 
                                                                                    onClick={this.handleSend} 
                                                                                    disabled={cartdisabled} 
                                                                                    className={"collapseB cartButtonTopic " + (cartdisabled && "disabled")} 
                                                                                    type="ghost">
                                                                                <Icon type="info-circle" />{cartdisabled ? "Added":"Add to Info Cart"}
                                                                            </Button>}
                        </div>    
                        {imgTitle}
                        <Button type="primary" onClick={() => this.setState({expanded : topic.Sections.map((section, index) => ''+index)})} className="collapseB" ghost  >Expand All</Button>
                        <Button type="primary" onClick={() => this.setState({expanded : []})} className="collapseB" ghost >Collapse All</Button>
                        <Collapse
                            // accordion
                            onChange={(activeKey) => {
                                B.expandedTopic = activeKey;
                                this.setState({expanded: activeKey});
                            }}
                            bordered={false}
                            activeKey={this.state.expanded}
                            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                            className="collapseTopic"
                            >
                            {topic.Sections.filter(section => ((section.type !== 'collection')) || this.store.getByID(section['objects_'+section.ID][0])).map((section, index) => <Panel header={<div className="sectionTitle">{section["title_"+section.ID]}</div>} key={index} style={customPanelStyle}><div key={index}>{this.TopicSection(section, index)}</div></Panel>)}
                        </Collapse>
                        

                        {parentCollections ?
                            <Row type="flex" justify="left" style={{marginTop:'10px'}}>
                            <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                                <div className="itemFieldTitle">Included In</div>
                                <div className="itemFieldBody">
                                {parentCollections.map( (item, index) => {
                                    const itm2 =  this.store.getByID(item.id);
                                    if (EditCtl.editMode && itm2.Status!=="Published")
                                        return <Link to={"/" + topic.sid + "/item/" + item.id} key={index}>
                                                    <Tag className={'collectionLink'} style={{color:'orange'}}>{item.title}</Tag>
                                                </Link>
                   
                                    return  <Link to={"/" + topic.sid + "/item/" + item.id} key={index}>
                                                <Tag className={'collectionLink'}>{item.title}</Tag>
                                            </Link>
                                })}
                                </div>
                            </Col>
                        </Row>
                        : null
                        }

                        { this.state.currentUser ?

                            <div className="topicOwner">
                                <Row type="flex" >
                                    <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                                        <div className="itemFieldTitle">Need Help?</div>
                                        <div className="itemFieldBody">
                                            {topic.Owner}
                                            {/* {renderHTML(`<div>${topic.Owner}</div>`)} */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row type="flex" >
                                    <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                                    <div className="lastModified">
                                        {topic.name && EditCtl.editMode && <div><br></br>Last submitted
                                        by {topic.name} on {new Date(topic.date).toISOString().replace('T', ' ').slice(0, 19)}</div>}
                                    </div>
                                    </Col>
                                </Row>
                            </div> : null } 
                    </div>
                    <FooterComponent props={this.props} data={this.storeDef}/>
                    <BackTop visibilityHeight={1500}/>
                </div>
            </div>
        );
    }
}